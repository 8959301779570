// src/pages/Dashboard.js
import React from 'react';
import { Box, Typography, Container } from '@mui/material';

function Dashboard() {
  return (
    <Container>
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Dashboard
        </Typography>
        {/* Include dashboard components here */}
      </Box>
    </Container>
  );
}

export default Dashboard;
