// src/pages/Home.js
import React from 'react';
import { Typography, Container, Box, Button } from '@mui/material';

function Home() {
  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4, textAlign: 'center' }}>
        <Typography variant="h2" component="h1" gutterBottom>
          IPCheck Service Services
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          We offer monthly vulnerability scans for URLs and IP Addresses (coming soon!).
        </Typography>
        <Button variant="contained" color="primary" href="/products">
          View Products
        </Button>
      </Box>
    </Container>
  );
}

export default Home;
