// src/pages/ForgotPassword.js
import React from 'react';
import { Container, TextField, Button, Typography } from '@mui/material';

function ForgotPassword() {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    fetch('/forgot-password', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email: data.get('email'),
      }),
    })
      .then((response) => {
        if (response.ok) {
          // Notify user that email has been sent
        } else {
          // Handle errors
        }
      })
      .catch((error) => {
        // Handle errors
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <div
        style={{
          marginTop: '8%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        <form
          onSubmit={handleSubmit}
          noValidate
          style={{ marginTop: 1, width: '100%' }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Enter your email"
            name="email"
            autoComplete="email"
            autoFocus
            InputLabelProps={{ style: { color: '#fff' } }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ marginTop: 3, marginBottom: 2 }}
          >
            Reset Password
          </Button>
        </form>
      </div>
    </Container>
  );
}

export default ForgotPassword;
