// src/pages/Abuse.js
import React from 'react';
import { Container, Typography } from '@mui/material';

function Abuse() {
  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Report Abuse
      </Typography>
      {/* Add abuse reporting content here */}
    </Container>
  );
}

export default Abuse;
