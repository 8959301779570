// src/pages/TwoFASetup.js
import React, { useState } from 'react';
import { Container, Button, Typography } from '@mui/material';

function TwoFASetup() {
  const [qrCode, setQrCode] = useState(null);

  const handleSetup = () => {
    fetch('/2fa/setup')
      .then((response) => response.json())
      .then((data) => {
        setQrCode(data.qrCodeUrl);
      })
      .catch((error) => {
        // Handle errors
      });
  };

  return (
    <Container>
      <Typography variant="h5" component="h1" gutterBottom>
        Set Up Two-Factor Authentication
      </Typography>
      {!qrCode ? (
        <Button variant="contained" onClick={handleSetup}>
          Generate QR Code
        </Button>
      ) : (
        <>
          <img src={qrCode} alt="2FA QR Code" />
          <Typography variant="body1" gutterBottom>
            Scan this QR code with your authentication app.
          </Typography>
        </>
      )}
    </Container>
  );
}

export default TwoFASetup;
