// src/pages/AboutUs.js
import React from 'react';
import { Container, Typography } from '@mui/material';

function AboutUs() {
  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        About Us
      </Typography>
      {/* Add about us content here */}
    </Container>
  );
}

export default AboutUs;
