// src/pages/Products.js
import React from 'react';
import { Container, Typography } from '@mui/material';

function Products() {
  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Products
      </Typography>
      {/* Add product information here */}
    </Container>
  );
}

export default Products;
