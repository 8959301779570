// src/App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline'; // Optional: for consistent styling resets
import theme from './theme';
import NavBar from './components/NavBar';
import Home from './pages/Home';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Dashboard from './pages/Dashboard';
import Products from './pages/Products';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import Abuse from './pages/Abuse';
import ForgotPassword from './pages/ForgotPassword';
import TwoFASetup from './pages/TwoFASetup';

function App() {
  return (
    <ThemeProvider theme={theme}>
      {/* CssBaseline helps apply Material-UI baseline styling */}
      <CssBaseline />

      {/* App Content */}
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<SignIn />} />
        <Route path="/register" element={<SignUp />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/products" element={<Products />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/abuse" element={<Abuse />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/2fa/setup" element={<TwoFASetup />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
