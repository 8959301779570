// src/pages/ContactUs.js
import React from 'react';
import { Container, Typography } from '@mui/material';

function ContactUs() {
  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Contact Us
      </Typography>
      {/* Add contact form or information here */}
    </Container>
  );
}

export default ContactUs;
