// src/components/NavBar.js
import React from 'react';
import { AppBar, Toolbar, Tabs, Tab } from '@mui/material';
import { Link } from 'react-router-dom';

function NavBar() {
  return (
    <AppBar position="static">
      <Toolbar>
        <Tabs value={false} textColor="inherit" indicatorColor="secondary">
          <Tab label="Home" component={Link} to="/" />
          <Tab label="Products" component={Link} to="/products" />
          <Tab label="About Us" component={Link} to="/about-us" />
          <Tab label="Contact Us" component={Link} to="/contact-us" />
          <Tab label="Abuse" component={Link} to="/abuse" />
          <Tab label="Login" component={Link} to="/login" />
          <Tab label="Register" component={Link} to="/register" />
        </Tabs>
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;
